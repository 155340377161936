import spielklasseHandler from './spielklasseHandler';

export const getSignUpNumberForClub = (clubId, spielklasse, singUps) => {

	console.log(spielklasse);
	console.log(singUps);
	
	
	let number = 1;
	if (singUps.length > 0) {
		singUps.forEach(signUp => {
			if (signUp.verein.objectId === clubId && signUp.spielklasse === spielklasse ) {
				number += 1;
			}
		});
	}
	return number;
};

export const signUpNameHandler = (signUpId, signUps, showClass, short) => {
	if (!signUps || !signUps.length) return {
		label: '',
		logo: null
	};
	
	const singUp = signUps.find(singUpToFind => singUpToFind.objectId === signUpId);
	const clubs = signUps.map(singUpToFind => ({...singUpToFind.verein, singUpId: singUpToFind.objectId}));
	const clubCopy = {...singUp?.verein, signUpId};

	if (clubCopy) {
		const shortName = clubCopy.kurz || `${clubCopy?.name?.slice(0,4)} ...`;
		const teamName = short ? shortName : clubCopy.name;

		if (typeof clubCopy === 'object' && clubCopy?.name) {
			clubCopy.label = showClass ?`${teamName} - ${spielklasseHandler(singUp.spielklasse).short}`: teamName ;
		};
		const multipleClubs = [];
		clubs.forEach((clubToCheck) => {
			if (clubToCheck.objectId === clubCopy.objectId) {
				multipleClubs.push({...clubToCheck, number: multipleClubs.length + 1});
			}  
		});
    
		if (multipleClubs?.length > 1) {
			const number = singUp?.nummer?.toString() || (multipleClubs.find(club => club.singUpId === clubCopy.signUpId).number).toString();
			clubCopy.label = showClass ? `${teamName} ${number} - ${spielklasseHandler(singUp.spielklasse).short}` : `${teamName} ${number}`;
		}
	}
	return {
		label: clubCopy.label,
		logo: clubCopy.logo || null
	};
};
