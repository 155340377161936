import { get } from 'lodash';
import { signUpNameHandler } from './signUpHelpers';

const getTeamLabel = (game, games, short, showClass, groups)  => {
	const teamsArray = ['team1', 'team2', 'referee'];
	const teamObject = {
		team1: {},
		team2: {},
		referee: {}
	};

	teamsArray.forEach(teamKey => {
		const ugInd = game?.group?.untergruppen?.findIndex(ug => ug.id === game[`${teamKey}_group`]) ;
		
		if ((game.type === 'akk' ||  ugInd === -1) && game.type !== 'plz' && game.type !== 'krz') {
			const groupsCopy = groups ?  [...groups] : [];
			let meldungId =  '';

			let ugIndex = -1;
			let gruppe;
			groupsCopy.forEach(group => {
				const untergruppenIndex = group.untergruppen.findIndex(ug => ug.id === game[`${teamKey}_group`]) ;
				if (untergruppenIndex !== -1) {
					ugIndex = untergruppenIndex;
					gruppe = group;
				}
 			});

			if (ugIndex !== -1) {
				const meldung = get(gruppe, `untergruppen[${ugIndex}].meldungen`, undefined);
				meldungId = gruppe.untergruppen[ugIndex]?.meldungen[game[`${teamKey}_index`] - 1];
				
				if (meldung?.length > 0 && meldungId) {
				   teamObject[teamKey] = {
						...teamObject[teamKey], 
						logo: signUpNameHandler(meldungId, gruppe.meldungen?.results, showClass, short).logo, 
						label: signUpNameHandler(meldungId, gruppe.meldungen?.results, showClass, short).label,
						meldungId
					};
			   } else {
				   teamObject[teamKey] = {
					   label: `${game[`${teamKey}_index`]  }. ${get(game, `gruppe.untergruppen[${ugIndex}].label`, 'Unbekannt' )}`
					};
			   }
			}
			return null;
		
		}
		if (game.type === 'group' || game.type === 'grp' || game.type === 'ent') {
			
			let meldungId =  '';
			const untergruppenIndex = game?.group?.untergruppen?.findIndex(ug => ug.id === game[`${teamKey}_group`]) ;
			const meldung = get(game, `group.untergruppen[${untergruppenIndex}].meldungen`, undefined);
			meldungId = game?.group?.untergruppen[untergruppenIndex]?.meldungen[game[`${teamKey}_index`] - 1];
			
			if (meldung?.length > 0 && meldungId) {
				teamObject[teamKey] = {
					...teamObject[teamKey], 
					label: signUpNameHandler(meldungId, game.group?.meldungen?.results, showClass, short).label,
					logo: signUpNameHandler(meldungId, game.group?.meldungen?.results, showClass, short).logo,
					meldungId
					
				};
			} else {
				
				teamObject[teamKey] = {
					label: `${game[`${teamKey}_index`]  }. ${get(game, `group.untergruppen[${untergruppenIndex}].label`, 'Unbekannt' )}`
				};
			}
			// game.group?.meldungen?.results.forEach(meldung => {
			// 	if (meldung.objectId === meldungId) {
			// 		team = meldung.mannschaft.name;
			// 	}
			// });
			return null;
		}
		if (game.type === 'cross' || game.type === 'krz' || game.type === 'place' || game.type === 'plz') {
			
			const untergruppenIndex = game.group.untergruppen.findIndex(ug => ug.id === game[`${teamKey}_group`]) ;
			const untergruppe = game.group.untergruppen[untergruppenIndex];
			if (game[`${teamKey}_game`] ) {
				const teamGame = games.find(gameToFind => gameToFind.objectId === game[`${teamKey}_game`].objectId);
				const teamIndex =  game[`${teamKey}_index`];
				if (teamIndex === 2 && teamGame) {
					if (teamGame.winner) {
						teamObject[teamKey] = {
							...teamObject[teamKey], 
							label: signUpNameHandler(teamGame?.winner?.objectId, game.group?.meldungen?.results, showClass, short).label,
							logo: signUpNameHandler(teamGame?.winner?.objectId, game.group?.meldungen?.results, showClass, short).logo,
							meldungId: teamGame?.winner?.objectId
						};
					} else {
						teamObject[teamKey] = {label: `Sieger aus Spiel ${teamGame.spielNr}`};
					}
				} 
				if (teamIndex === 1 && teamGame) {
					if (teamGame.loser) {
						teamObject[teamKey] = {
							...teamObject[teamKey], 
							label: signUpNameHandler(teamGame?.loser?.objectId, game.group?.meldungen?.results).label,
							logo: signUpNameHandler(teamGame?.loser?.objectId, game.group?.meldungen?.results).logo,
							meldungId: teamGame?.loser?.objectId
						};
					} else {
						teamObject[teamKey] = {label: `Verlierer aus Spiel ${teamGame.spielNr}`};
					}
				} 
			} else if ( untergruppe?.abgeschlossen === true) {
				teamObject[teamKey] =  {
					...teamObject[teamKey], 
					label: signUpNameHandler(untergruppe.standings[game[`${teamKey}_index`] - 1].meldungId, game.group?.meldungen?.results, showClass, short).label,
					logo: signUpNameHandler(untergruppe.standings[game[`${teamKey}_index`] - 1].meldungId, game.group?.meldungen?.results, showClass, short).logo,
					meldungId: untergruppe.standings[game[`${teamKey}_index`] - 1].meldungId
				};
			} else if (game[`${teamKey}_index`]) {
				teamObject[teamKey] = {label: `${game[`${teamKey}_index`].toString()}. ${game.group?.untergruppen[untergruppenIndex]?.label || 'Unbestimmt'}`};
			} else {
				teamObject[teamKey] = {label: 'Unbestimmt'};
			}
			return null;
		}
		return null;
	});

	return teamObject;
};

export default getTeamLabel;